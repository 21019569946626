import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import iconTopDiv from "../assets/icons/icon-top-div-green.png";
import iconCompass from "../assets/icons/icon-compass.png";
import iconHelm from "../assets/icons/icon-helm.png";
import safeZone1 from "../assets/images/safe-zone1.png";
import topWave from "../assets/images/top-wave.svg";
import GetTheApp from "../components/ui/sections/GetTheApp";
import TheThreeRules from "../components/ui/sections/TheThreeRules";
import Card from "../components/ui/cards/Card";
import tedHead from "../assets/images/ted-head.png";
import Testimonials from "../components/ui/sections/Testimonials";
import VimeoPlayer from "../components/vimeoplayer/VimeoPlayer";
import bottomWave from "../assets/images/bottom-wave.svg";
import GuideDownload from "../components/ui/GuideDownload";

const IndexPage = () => {
  return (
    <Layout>
      <Seo seo={{ metaTitle: "Home" }} />
      <main>
        <section id="safe-harbor-introduction">
          <div className="small container">
            <div className="between gap flex items-center">
              <div className="column flex w-80">
                <img className="w-50 mb-4" src={topWave} alt="" />
                <h1 className="grey">Deepen & Thrive in Your Relationships</h1>
              </div>
              {/* <img className="w-40" src={monsters1} alt="" /> */}
            </div>
            <div className="wrap between gap-small mt-10 flex items-center">
              <div className="w-30 column center flex items-center">
                <img className="w-100" src={tedHead} alt="" />
                <div className="w-80">
                  <h4 class="mt-3 text-center">Ted Barrett-Page, JD, LCSW</h4>
                  <p class="small grey-light bold mt-2 text-center"></p>
                </div>
              </div>
              <div className="column flex w-60">
                <h1>Hello!</h1>
                <p className="medium black mt-5">
                  <strong>Welcome!</strong> I'm Ted, founder of The Safe Harbor
                  Method™. With over 35 years of experience as a couples and
                  family therapist, I've discovered a game-changing way to help
                  you navigate those tough conversations that are bound to pop
                  up. Our approach is accessible to everyone and can be applied
                  to any type of relationship.
                </p>
                <GuideDownload />
              </div>
            </div>
          </div>
          <h1 className="grey mt-20 text-center">The Safe Harbor Method™</h1>
        </section>
        <section id="safe-harbor-zone" className="bg-purple relative">
          <img
            src={iconTopDiv}
            className="absolute"
            style={{
              width: "150px",
              top: "0",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <div className="small container">
            <h1 className="blue text-center">
              Create a Safe Zone for Connection
            </h1>
            <div className="column between mt-5 flex items-center">
              <img src={safeZone1} className="w-30 mb-5" />
              <div className="column gap flex items-center">
                <p className="large white mb-3 text-center">
                  Agree to the 3 rules (below). Alternate sharing and listening
                  for <span className="orange">2 min</span> max with a timer.
                  That’s it. Super simple and yet the start of something
                  special.
                </p>
                <div className="mt-3">
                  <Link className="btn primary" to="/the-safe-harbor-method">
                    More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TheThreeRules />

        <section>
          <div className="column mb-5 flex items-center">
            <h1 className="mb-3">2.5 Minute Introduction Video</h1>
            <img style={{ maxWidth: "300px" }} src={bottomWave} alt="" />
          </div>

          <VimeoPlayer videoId="891644154" />
        </section>
        <section>
          <div className="wrap center gap-large flex">
            <div className="w-30">
              <Card
                icon={iconCompass}
                title="Self-Guided Sessions"
                link="/self-guided-sessions"
                linkTitle="Get Started"
              >
                <p className="large white mt-4 text-center">
                  Learn the basics and start today! It's{" "}
                  <span className="orange bold italic">FREE</span> and easy to
                  do.
                </p>
              </Card>
            </div>
            <div className="w-30">
              <Card
                icon={iconHelm}
                title="Choose a Guide"
                link="/choose-a-guide"
                linkTitle="Get Started"
              >
                <p className="large white mt-4 text-center">
                  To offer help and answer questions. Schedule Today!
                </p>
              </Card>
            </div>
          </div>
        </section>
        {/* <Articles featured /> */}
        <GetTheApp />
        <Testimonials />
      </main>
    </Layout>
  );
};

export default IndexPage;
