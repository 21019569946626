import React from 'react';
import downloadIcon from '../../assets/icons/icon-download.svg';
import guideIcon from '../../assets/icons/icon-guide.svg';
import safeHarborStarterGuide from '../../assets/files/safeharbor-starter7-6-23.pdf';

const GuideDownload = () => {

    const handleClick = () => {
        const link = document.createElement('a');
        link.href = safeHarborStarterGuide;
        link.target = '_blank';
        link.download = 'Safe Harbor Starter Guide.pdf';
        link.click();
    };

    return (
        <div className="mt-3 flex items-center between download-guide-btn gap-small" onClick={handleClick}>
            <div className="flex items-center gap-small start">
              <img src={guideIcon}/>
              <span>Download the Safe Harbor Starter Guide</span>
            </div>
            <img src={downloadIcon} />
        </div>
    )
}

export default GuideDownload;